

.App-ebillet {
    display: table;
    width: 1400px;
    max-width: 100%;
    margin: auto;
    font-family: oswald;

    .overlay {
        background-color: #303030;
    }
    //background-color:#303030;
    .content-wrapper {
        max-width: 100%;
        //width:1400px;
        .header-section {
            background-color: #3b3b3b;
        }
    }
    /*.film-details {
    width: 1400px;
    margin: auto;
        .Movie {
        width: 1170px;
        margin: auto
    }
}*/
    .film-details {
        font-family: Oswald;
        background-color: #303030;

        .Movie {
            margin: auto;
            max-width: 1170px;
            background-color: #303030 !important;
        }

        .orgs_container {
            max-width: 1170px;
            margin: auto;
        }
    }

    button:hover {
        box-shadow: none;
        cursor: pointer;
    }

    button {
        border: none;
        outline: none;
        background: transparent;
        //color:white
    }

    .ebilletProgram, .AllMovies {
        font-family: oswald;
        background-color: #303030;
        //background-color: #262626;

        .Calendar {
            top: 122px;
            max-width: 1170px;
            margin: auto;
            background-color: transparent;

            .icon-close {
                right: 31%;
            }

            .DayPicker {
                color: white;
                font-size: 20px;
                font-weight: bold;
            }
        }

        .chooseFromContainer {
            //border: 1px solid red;
            max-width: 1170px;
            margin: auto;
            background-color: #3b3b3b;
            height: 115px;
            color: white;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;

            .chooseDay {
                font-weight: 600;
                font-size: 22px
            }

            .days-btns {
                min-width: 40px;
                color: #d2d2d2;
                margin-right: 8px;
                border: 1px solid #757575;
                // font-size: 16px;
                font-weight: normal;
            }

            .day_btns_0 {
                width: 56px;
            }

            .day_btns_1 {
                width: 77px;
            }

            .day_btns_2, .day_btns_3, .day_btns_4 {
                width: 98px;
                text-transform: capitalize;
            }
        }


        .region {
            /* border:1px solid; */
            max-width: 1170px;
            // background-color: #3b3b3b;
            //height: 121px;
            /* padding: 0px 50px; */
            color: white;
            /* border-radius: 2px; */
            display: flex;
            /*margin: 0 auto*/
            /* justify-content: space-around; */
        }

        .region-title, .choose-title {
            font-size: 22px;
            font-weight: 600;
            /* line-height: 1.05px; */
        }
        //vælge region style
        .regionPointer:hover {
            cursor: pointer
        }

        .chooseRegion {
            width: 177px;
            z-index: 99;
            /* height:33px; */
            border-radius: 2px;
            border: 1px solid #757575;
            color: #999;
            margin-top: -15px;
            // background-color: #3a3a3a;
            position: relative;
        }

        .region {
            margin: 0;
            //padding: 5px;
            letter-spacing: 0.44px;
        }

        .one {
            border-radius: 2px;
            background-color: #f7f7f7;
            padding: 0px 7px;
            font-size: 12px;
            position: absolute;
            top: 7px;
            right: 45px;
            color: black
        }

        .rotated {
            transform: rotate(180deg);
            margin-top: 12px;
        }

        .checkCheckbox {
            border: 1px solid #757575;
            width: 19px;
            height: 19px;
        }
        // vælge biograf style
        .chooseCinema {
            border: 1px solid #757575;
            width: 378px;
            /* height:33px; */
            border-radius: 2px;
            //   background-color: #3a3a3a;
            margin-top: -15px;
            color: #999;
        }

        .scroll {
            width: 378px;
            /* p:hover {
            cursor: pointer;
            color: white;
            font-size: 20px
        }*/
        }

        .scroll::-webkit-scrollbar {
            width: 33px;
        }

        .scroll::-webkit-scrollbar-thumb {
            background: #3b3b3b;
            border-right: solid 2px transparent;
            box-shadow: inset 1px 1px 1px 100px #757575;
        }

        .scroll::-webkit-scrollbar-track {
            margin: 2px;
        }
    }
    //program style
    .programFilmContainer {
        //border:1px solid ;
        // background-color: #262626;
        color: white;
        margin: auto;
        max-width: 1170px;

        .program-header {
            display: flex;
            justify-content: space-between;
            max-width: 1170px;
            //padding: 5px;
            .program {
                font-size: 30px;
            }
        }

        .all-film-program {
            display: inline-flex;
            flex-wrap: wrap;
            gap: 7.5px;
            justify-content: flex-start;
            margin: 20px 0px;
            font-family: Oswald;
        }

        .film-program-item {
            width: 228px;
            height: 469px;
            background-image: linear-gradient(to top, #d4d4d4, #eee)
        }

        .film-btn-container {
            padding: 5px 12px;
            position: relative;
        }

        .film-program-btn {
            border: none;
            position: absolute;
            font-weight: bold;
            width: 204px;
            top: 80px;
            height: 31px;
            border-radius: 2px;
            background-image: linear-gradient(to top, #950200, #9e0200);
            color: white;
        }
    }
    //sorting style
    .sorting {
        /* border:1px solid white; */
        padding-top: 50px;
        margin-right: -1px;
        display: grid;
        grid-template-columns: .1fr 1fr;
    }

    .sortingContainer {
        border: 1px solid #757575;
        width: 228px;
        color: #999;
        background-color: #3a3a3a;
        /* height:33px; */
        margin-top: -5px;
        margin-left: 5px;
        position: relative;
    }

    .sortingDiv {
        border: 1px solid #757575;
        background-color: #3a3a3a;
        color: #d2d2d2;
        margin-left: -1px;
        padding: 0px 8px;
        position: absolute;
        z-index: 30;
        width: inherit;

        p:hover {
            cursor: pointer
        }
    }

    .line {
        position: absolute;
        border-left: 0.1px solid #757575;
        top: 0;
        height: 31px;
        right: 35px
    }

    .rotated {
        transform: rotate(180deg);
        margin-bottom: 15px;
    }

    .ellipsis-twoline {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        /* width:228px; */
        height: 76px;
        color: #000;
        white-space: pre-wrap;
        /* padding:10px */
    }
    //--------------FILM DETAILS STYLE

   /* .film-details*/ .info-container {
        // height: 460px;
        background-color: white;
        padding: 20px;

        .Poster {
            // width: 25% ;
            img {
                height: 100%;
                width: 100%
            }
        }

        .movie-info {
            width: 70%;
            margin-left: 20px;

            .film-title {
                font-size: 25px;
                font-weight: bold
            }

            .film-desc {
                margin-top: 20px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                width: 30%
            }
        }
    }

    .chooseVersionContainer {
        background-color: #404040;

        button {
            font-family: oswald;
            font-size: 14px !important;
            font-weight: normal !important;
            margin-top: 8px;
            margin-right: 8px;
            border: 1px solid #757575;
            padding-top: 3px;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 2px;
        }
        // width:1400px;
        // margin:auto
    }

    .chooseDayContainer {
        background-color: #444;
        color: white;
        height: 83px;
        position: relative;
        //  max-width: 1170px;
        margin: auto;

        .days-btns {
            width: 127px;
        }

        .Calendar {
            top: 83px;
            width: 500px;
            margin: auto;
            right: -52%;
            background-color: transparent;

            .icon-close {
                right: 5%
            }
        }

        button {
            margin-top: 20px;
            margin-right: 8px;
            border: 1px solid #757575;
            font-size: 14px !important;
            font-weight: normal !important;
        }
        // max-width: 1400px
    }

    .back_btn_cont {
        max-width: 1170px;
        margin: auto;
        position: relative;

        .back_arrowback {
            display: inline-block;
            position: absolute;
            left: 10px;
            top: 5px;
        }

        .back_btn {
            width: 175px;
            height: 33px;
            border-radius: 2px;
            background: linear-gradient(226deg, #950200 0%, #9E0200 99.79%);
            color: white;
            font-size: 17px;
            font-weight: 700
        }
    }

    .orgs_container {
        .showtime_buttons {
            width: 130px;
            // background-image: linear-gradient(to top, rgb(149, 2, 0), rgb(158, 2, 0));
            color: white;
            margin-right: 10px;
            margin-bottom: 10px;
            //padding: 5px;
            text-decoration: none;
            display: block;
            text-align: center;

            .count-down {
                background-color: grey
            }
        }


        .bio_name_container {
            height: 54px;
            margin-top: -20px;
            background-image: linear-gradient(to top, #c7c7c7, #ddd)
        }

        .bio_name {
            font-size: 20px;
            font-weight: bold;
            color: #474747;
            padding-left: 25px;
            padding-top: 10px;
        }
    }
    /*----------------TIMER ------------------*/
    /*.timer-wrapper {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 50px auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid black;
    border-radius: 10px;
    background-color: rgb(13, 28, 156);
}

.timer-section {
    margin: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.timer {
    width: 30px;
    height: 40px;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    margin: 2px;
    font-family: 'Metrophobic', sans-serif;
    text-align: center;
    line-height: 40px;
    font-size: 30px;
    -webkit-transition: 0.2s all;
    -o-transition: 0.2s all;
    transition: 0.2s all;
}*/
    /*--------------Biografer page styling -----------*/
    .biograferContainer {
        background-color: #303030;
        // margin-top:-30px;
        .bio_title {
            font-size: 30px;
            color: white;
            font-weight: 500;
            letter-spacing: 0.3px;
        }

        .bio_count {
            font-size: 20px;
            font-weight: 600;
            color: #6f6f6f;
            letter-spacing: 0.2px
        }

        .biografer {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            padding: 20px 5px;
        }
    }
    /*--------All TRAILERS PAGE STYLE ------------*/
    .TrailersContainer {
        background-color: #303030;
        max-width: 1170px;

        .title {
            font-size: 30px;
            color: white;
            font-weight: 500;
            letter-spacing: 0.3px;
        }

        .trailer-header {
            display: flex;
            justify-content: space-between;
            padding-top: 30px;

            .sorting {
                padding-top: 40px;
            }
        }

        .trailers {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .movienameContainer {
                display: flex;
                padding: 0px 20px;
                justify-content: space-between;
                background-color: #eaeaea;

                .movie_name {
                    color: #000;
                    font-size: 22px;
                    text-overflow: ellipsis;
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0.133px;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .trailerDiv {
                overflow: hidden;
                padding-top: 33%;
                position: relative;
                transition: padding-top .4s ease-out;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                vertical-align: middle;
                width: 100%;
                background: rgba(0, 0, 0, 0.45);
            }

            .player {
                bottom: 0;
                height: 100%;
                left: 0;
                overflow: hidden;
                padding-top: 0 !important;
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;

                .video-react-big-play-button {
                    top: 40% !important;
                    left: 40% !important;
                    width: 50px !important;
                    background-color: white;
                    color: black;
                    border-radius: 50%
                }

                .video-react-poster {
                    background-size: cover;
                    //background: rgba(0, 0, 0, 0.45);
                }

                .icon-play {
                    text-align: center;
                    position: absolute;
                    text-align: center;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                }
            }
        }
    }
    /*--------------------------- Kommende film style  ---------------*/
    .FutureFilmsSection {
        background-color: #404040;
        height: 115px;

        .FutureFilmsHeader {
            max-width: 1170px;
            margin: auto;
            color: white;
            display: flex;
        }
    }

    .futureMovies_title {
        max-width: 1170px;
        margin: auto;
        color: white;
        font-size: 30px;
        font-weight: 600;
        padding-bottom: 30px;
        padding-top: 40px;
    }

    .FutureMoviesContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 1170px;
        margin: auto;

        .FutureMovieListItem {
            display: flex;
            justify-content: space-between;
            //height: 351px;
            margin-bottom: 20px;
            width: 49%;

            .poster {
                width: 40%;
                position: relative;

                .age-banner {
                    position: absolute;
                    bottom: 6px;
                    right: 6px;
                }
            }

            img {
                height: auto;
                width: 100%;
            }
        }

        .FutureMovieListItem:nth-child(odd) {
            margin-right: 20px;
        }

        .movie_desc {
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 14px;
            color: #353535;
        }
    }

    .FutureMovieDesc {
        background-image: linear-gradient(to top, rgb(212, 212, 212), rgb(238, 238, 238));
        width: 60%;
        position: relative;
        padding-left: 24px;
        padding-right: 30px;

        .movie-name {
            color: #000;
            font-size: 22px;
        }
    }

    a.disabled {
        pointer-events: none;
        cursor: default;
        background-color: gray;
        background-image: none
    }
    /*--------------------------- MEDIA QUERIES  ---------------*/
    @media (min-width:600px) {
        .FutureMovieDesc .movie-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    @media (max-width:1020px) {
        .FutureMoviesContainer .FutureMovieListItem {
            //flex-wrap:nowrap
            width: 50%;
            margin-bottom: 0px;
            margin-right: 0px
        }

        .FutureMoviesContainer .FutureMovieListItem:nth-child(odd) {
            margin-right: 0px
        }
    }

    @media (max-width:1400px) {
        .App-ebillet .content-wrapper .header-section {
            // background-color: transparent;
        }

        .chooseDayContainer {
            height: auto;
            padding-bottom: 20px;
        }

        .AllMovies {
            //padding: 10px;


            .programFilmContainer {
                //margin-top: 190px
            }

            .program-header {
                //border: 1px solid white;
                height: 50px;

                .program {
                    font-size: 20px;
                    margin-top: 8px;
                }

                .sorting {
                    padding-top: 12px
                }
            }

            .chooseFromContainer {
                //  border: 1px solid red;
                flex-direction: column;
                font-size: 14px;
                height: auto;
                padding-bottom: 20px;
            }
        }
    }

    @media (max-width:750px) {
        .FutureMoviesContainer .FutureMovieListItem {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    @media (max-width:600px) {
        .FutureFilmsSection {
            height: auto
        }

        .FutureFilmsHeader {
            flex-direction: column
        }

        .film-details {
            padding: 10px;

            .chooseVersionContainer button {
                padding-left: 0px;
                padding-right: 0px;
                padding: 5px;
            }
        }

        .AllMovies {
            font-size: 14px;

            .chooseFromContainer {
                .chooseDay {
                    font-size: 15px;
                }

                .region-title {
                    font-size: 15px
                }
            }
        }

        .orgs_container {
            .bio_name_container {
                height: 40px;
                margin-top: -15px;
            }

            .bio_name {
                font-size: 15px;
            }

            .showtime_buttons {
                //width: 80px;
            }
        }

        .biograferContainer {
            .biografer {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    @media (max-width:415px) {
        .AllMovies {
            .chooseCinema {
                width: 300px;
            }
        }

        .orgs_container {
            .showtime_buttons {
                //width: 50px;
            }
        }
    }

    @media (max-width:480px) {
        .header-section {
            padding: 20px;
        }

        .program-header {
            padding: 20px
        }

        .FutureFilmsSection .FutureFilmsHeader {
            padding-left: 20px;
            padding-bottom: 20px;
        }

        .futureMovies_title {
            padding-left: 20px
        }

        .FutureMoviesContainer .FutureMovieListItem {
            flex-direction: column;
            padding: 20px;

            .poster {
                width: 100%;
            }
        }

        .FutureMovieDesc {
            width: 100%;
            height: 320px;
            padding-left: 5px;
            padding-right: 5px;
        }

        .programFilmContainer .film-program-item {
            width: 100%;
            padding: 20px;
            text-align: center;
            background-image: none;
            height: auto;

            .ellipsis-twoline {
                color: white
            }
        }

        .programFilmContainer .film-btn-container {
            padding: 0px
        }

        .programFilmContainer .film-program-btn {
            display: block;
            width: 100%;
            top: 40px;
        }
    }
}
